"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const framer_motion_1 = require("framer-motion");
/**
 * @name AnimationFail
 * @desc Animation with green checkmar and mostly complete circle
 * @notes SVG file location: src/images/svg/checkmark_200x200.svg, src/images/svg/cutcircle_200x200.svg
 * @param color string
 * @param width string
 * @param height string
 * @param margin string
 * @param minHeight string
 * @param maxHeight string
 * @returns
 */
const AnimationSuccess = ({ color = 'green', width = '20vw', height = '20vw', margin = '40px auto', minHeight = '120px', maxHeight = '240px', top = 'initial', left = 'initial', }) => {
    const transitionCheckmark = {
        delay: 0.4,
        duration: 0.6,
        yoyo: Infinity,
        ease: 'easeInOut',
    };
    const transitionCircle = { duration: 0.8, yoyo: Infinity, ease: 'easeInOut' };
    return ((0, jsx_runtime_1.jsxs)("div", { style: {
            width: width,
            height: height,
            margin: margin,
            minHeight: minHeight,
            maxHeight: maxHeight,
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            top: top,
            left: left,
        }, children: [(0, jsx_runtime_1.jsx)("svg", { style: {
                    position: 'absolute',
                    minWidth: '60px',
                }, width: '60%', height: '60%', viewBox: "0 0 173 162", version: "1.1", xmlns: "http://www.w3.org/2000/svg", children: (0, jsx_runtime_1.jsx)(framer_motion_1.motion.path, { fill: "none", strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: "15", stroke: color, d: "M10.651,91.595l46.862,59.643l104.376,-140.587", initial: { opacity: 0, pathLength: 0, pathOffset: 1 }, animate: { opacity: 1, pathLength: 1, pathOffset: 0 }, transition: transitionCheckmark }) }), (0, jsx_runtime_1.jsx)("svg", { style: {
                    position: 'absolute',
                    minWidth: '120px',
                }, width: "100%", height: "100%", viewBox: "0 0 265 265", version: "1.1", xmlns: "http://www.w3.org/2000/svg", children: (0, jsx_runtime_1.jsx)(framer_motion_1.motion.path, { fill: "none", strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: "12", stroke: color, d: "M173.102,247.223c-13.005,6.931 -26.226,10.37 -40.972,10.37c-69.245,-0 -125.463,-56.218 -125.463,-125.463c-0,-69.245 56.218,-125.463 125.463,-125.463c69.245,-0 125.689,56.218 125.463,125.463c-0.106,32.225 -9.919,51.089 -28.096,74.914", initial: {
                        opacity: 0,
                        scale: 0.5,
                        pathLength: 0,
                        pathOffset: 1,
                    }, animate: {
                        opacity: 1,
                        scale: 1,
                        pathLength: 1,
                        pathOffset: 0,
                    }, transition: transitionCircle }) })] }));
};
exports.default = AnimationSuccess;
