"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const usehooks_ts_1 = require("usehooks-ts");
const react_redux_1 = require("react-redux");
const components_1 = require("components");
const config_1 = require("config");
const actions_1 = require("../../credit_application/actions");
const reducer_1 = require("../../credit_application/reducer");
const func_1 = require("./utils/func");
const api_1 = require("./utils/api");
/**
 * @component CheckPhone
 */
const CheckPhone = () => {
    const WIZARD_URL = '/credit-portal/apply/wizard';
    const PROVE_URL = '/credit-portal/prove';
    // Hooks
    const dispatch = (0, react_redux_1.useDispatch)();
    const proveApp = (0, react_redux_1.useSelector)(reducer_1.selectors.proveApp);
    const history = (0, react_router_dom_1.useHistory)();
    // Vars
    const delay = 5000; // 5 second intervals
    // State
    const [sessionJson] = (0, react_1.useState)(proveApp); // Session
    const [isAvailable, setIsAvailable] = (0, react_1.useState)(null); // Pure Service
    const [isPlaying, setPlaying] = (0, react_1.useState)(false); // Interval Active
    const [isSuccess, setSuccess] = (0, react_1.useState)(false); // Success
    const [isFailed, setFailed] = (0, react_1.useState)(false); // Failed
    const [urlLink, setUrlLink] = (0, react_1.useState)(undefined); // URL Link
    /**
     * @name useInterval
     * @desc Time interval for checking the status of the Pure Auth link
     *      from FindInfo step was clicked, and if so process and proceed to the next step.
     */
    (0, usehooks_ts_1.useInterval)(async () => {
        if (sessionJson) {
            const postResJson = await (0, api_1.getProveTransactionStatus)(sessionJson);
            if (postResJson?.status === 200) {
                const postRes = await postResJson?.json();
                afterResp(postRes);
            }
            else {
                setPlaying(false);
                return false;
            }
        }
        else {
            setPlaying(false);
            return false;
        }
    }, 
    // Delay in milliseconds or null to stop it
    isPlaying ? delay : null);
    /**
     * @name afterResp
     * @desc After retrieving the status, compare against the conditions in prcoessStatus, go to the next step
     * @param postRes ProcessStatusType
     */
    const afterResp = async (postRes) => {
        const currentStatus = (0, func_1.processStatus)(postRes); // Decide status and next step
        let formUrl = WIZARD_URL;
        if (currentStatus) {
            if (currentStatus === 'failed') {
                setPlaying(false);
                setUrlLink('');
                setFailed(true);
                history.push(formUrl);
            }
            if (currentStatus === 'verified') {
                // Store Prove Verify in redux
                dispatch((0, actions_1.setProveVerify)({
                    credit_application_id: postRes.credit_application_id ?? undefined,
                    location_id: postRes.location_id ?? undefined,
                    session_id: postRes.session_id ?? postRes?.transaction.session_id,
                    phone_number: postRes.phone_number ?? postRes?.transaction.phone,
                    ssn_last_4: postRes.ssn_last_4 ?? postRes?.transaction.ssn_last_4,
                    status: postRes?.status,
                }));
                const storeNum = postRes.ads_store_number; // query.get('storeNum')
                if (postRes?.location_id !== null && storeNum) {
                    formUrl = (0, config_1.makeStoreUrl)(Number(storeNum));
                }
                if (postRes?.credit_application_id !== null) {
                    formUrl = (0, config_1.makeStoreUrl)(Number(storeNum));
                }
                setPlaying(false);
                setUrlLink(formUrl);
                setSuccess(true);
            }
        }
    };
    /**
     * @name init
     * @desc Check if Pure is available and start the interval
     */
    const init = async () => {
        const resp = await (0, api_1.getProveAvail)();
        if (resp && resp.status === 200) {
            setIsAvailable(true);
            if (sessionJson && sessionJson?.phone_number) {
                setPlaying(true);
            }
        }
        else {
            setIsAvailable(false);
        }
    };
    // Effects
    (0, react_1.useEffect)(() => {
        init();
    }, []);
    (0, react_1.useEffect)(() => {
        if (isAvailable === false) {
            history.push('/credit-portal/apply/wizard');
        }
    }, [isAvailable]);
    (0, react_1.useEffect)(() => {
        // Start again if no session
        if (isAvailable && !sessionJson?.phone_number) {
            history.push(PROVE_URL);
        }
    }, [isAvailable, sessionJson]);
    return ((0, jsx_runtime_1.jsxs)("div", { children: [isAvailable && sessionJson && sessionJson?.phone_number && ((0, jsx_runtime_1.jsx)(CheckYourPhone, { phone: sessionJson.phone_number.slice(-4), success: isSuccess, failed: isFailed, url: urlLink, testLink: sessionJson.auth_url })), isAvailable && !sessionJson?.phone_number && (0, jsx_runtime_1.jsx)(StartAgain, {}), isAvailable === null && (0, jsx_runtime_1.jsx)(components_1.Spinner, {})] }));
};
exports.default = CheckPhone;
/**
 * @component CheckYourPhone
 * @desc Basic view component for checking phone number wait screen
 * @param phone string
 * @returns
 */
const CheckYourPhone = ({ phone, success, failed, url, testLink, }) => {
    const history = (0, react_router_dom_1.useHistory)();
    const handleClick = (0, react_1.useCallback)(() => {
        if (url) {
            history.push(url);
        }
    }, [url]);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: failed ? ((0, jsx_runtime_1.jsxs)("div", { className: "text-center", children: [(0, jsx_runtime_1.jsx)("h2", { className: "font-family-bold", children: "Verification Failed" }), (0, jsx_runtime_1.jsx)(components_1.AnimationFail, {}), (0, jsx_runtime_1.jsx)("div", { className: "p-20", children: (0, jsx_runtime_1.jsx)("p", { children: "Sorry, mobile verification failed. Please try again later." }) })] })) : ((0, jsx_runtime_1.jsx)("div", { className: "text-center", children: success ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)("h2", { className: "font-family-bold", children: ["Your Mobile Verification", (0, jsx_runtime_1.jsx)("br", {}), " is Complete"] }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("div", { className: "p-20", children: (0, jsx_runtime_1.jsx)("p", { children: "Please continue on this device by clicking the button below." }) }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("button", { onClick: handleClick, className: "button-primary button-warn-outline", children: "Continue on this Device" }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)(components_1.AnimationPhoneSuccess, {})] })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("h2", { className: "font-family-bold", children: "Check Your Phone" }), (0, jsx_runtime_1.jsxs)("div", { className: "p-20", children: [(0, jsx_runtime_1.jsx)(CheckPhoneHeader, { phone: phone }), (0, jsx_runtime_1.jsx)(components_1.AnimationPhoneWait, {}), testLink && ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("a", { href: testLink, target: "_blank", rel: "noreferrer", children: "SMS Test Link" })] }))] })] })) })) }));
};
/**
 * @component CheckPhoneHeader
 * @param phone string
 */
const CheckPhoneHeader = ({ phone }) => {
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("p", { children: "We need to verify your mobile carrier information. " }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsxs)("p", { children: ["A text message with a link was just sent to the phone number", (0, jsx_runtime_1.jsx)("br", { className: "touch-hidden" }), "ending in ", phone, ". \u00A0 ", (0, jsx_runtime_1.jsx)("br", { className: "touch-only" }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: '/credit-portal/apply/wizard', className: "btn-link", children: "I didn't receive the text" }), "."] }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsxs)("p", { children: ["Once you complete the phone verification we will route you to the", (0, jsx_runtime_1.jsx)("br", { className: "touch-hidden" }), " pre-qualification request page."] })] }));
};
/**
 * @component StartAgain
 */
const StartAgain = () => {
    return ((0, jsx_runtime_1.jsxs)("div", { className: "text-center", children: [(0, jsx_runtime_1.jsx)("h2", { className: "weight-700", children: "Sorry" }), (0, jsx_runtime_1.jsxs)("p", { children: ["No session detected. Please retry the application from the", ' ', (0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: "/credit-portal/prove/", children: "start" }), "."] })] }));
};
